SystemJS.config({
    baseURL: '/',
    warnings: true,
    meta: {
		'*' : {
			nonce: window.document.getElementsByTagName('script')[0].nonce,
		},
        angular: {
            format: 'global',
            exports: 'angular',
            build: false
        },
        rx: {
             exports: 'Rx'
        },
        'angular-mocks': {
            deps: ['angular']
        },
        'angular-animate': {
            deps: ['angular']
        },
        'ui-bootstrap': {
            deps: ['angular-animate']
        },
        'gmap': {
            exports: 'google.map', // or whatever this is
            format: 'global',
            scriptLoad: false,
        },
        'fbsdk': {
            exports: 'FB', // or whatever this is
            format: 'global',
            scriptLoad: false
        },
        'googlesdk': {
            exports: 'gapi', // or whatever this is
            format: 'global',
            scriptLoad: false
        },
        'translate': {
            deps: ['angular']
        },
        'angular-translate-loader-url': {
            deps: ['translate']
        },
        'angular-translate-loader-static-files': {
            deps: ['translate']
        },
        'angular-translate-interpolation-messageformat': {
            deps: ['angular-sanitize', 'translate', 'messageformat']
        },
        'messageformat': {
            exports: 'MessageFormat'
        },
        'angular-sanitize': {
            deps: ['angular']
        },
        'dynamicLocale': {
            deps: ['angular']
        },
        'LanguagesAndDateFormats': {
            build: false
        },
        'urlConstants': {
            build: false
        },
        'adminConfigConstants': {
            build: false
        },
        
        'themeTemplate': {
            build: false
        },
        'OAuthConfig': {
            build: false
        },
        'angular-cookies': {
            deps: ['angular']
        },
        'lib/angular-i18n/*': {
            deps: ['angular-translate-interpolation-messageformat']
        },
        'angular-messages': {
            deps: ['angular']
        },
        'angular-drag-and-drop-lists': {
            deps: ['ios-drag-drop']
        },
        'ios-drag-drop': {
            deps: ['angular']
        },
        'angular-dropdown-multiselect': {
            deps: ['angular']
        },
        'card': {
            exports: 'Card',
            format: 'global',
			scriptLoad: true,
            build: false,
        },        
        'angular-card': {
            deps: ['angular', 'card']
        },
        'angular-ui-router': {
            deps: ['angular']
        },
        'angular-ui-mask': {
            deps: ['angular']
        },
        'ui-select': {
            deps: ['angular']
        },
        'angular-media-queries': {
            deps: ['angular']
        },
        'tinymce': {
            deps: ['angular' ]
        },
        'angular-ui-tinymce': {
            deps: ['angular', 'tinymce', 'ui-tinymce-theme', 'tinymce-plugins']
        },
        'image-cropper': {
            deps: ['angular']
        },
        'angular-recaptcha': {
            deps: ['angular']
        },
        'markerclusterer': {
            format: 'cjs'
        },
    },
    map: {
        angular: 'lib/angular/angular.js',
        'angular-mocks': 'lib/angular-mocks/angular-mocks.js',
        'ui-bootstrap': 'lib/angular-ui/ui-bootstrap-tpls.js',
        'angular-animate': 'lib/angular/angular-animate.js',
        rx: "lib/rx/rx.all.js",
        BaseStore: 'client/core/flux/base-store',
        'rzslider': 'lib/rz-slider/rzslider.js',
        'markerclusterer': 'lib/google-maps/markerclusterer.js',
        'gmap': 'https://maps.googleapis.com/maps/api/js?libraries=geometry,places',
        'translate': 'lib/angular-translate/angular-translate.js',
        'messageformat': 'lib/messageformat/messageformat.js',
        'angular-sanitize': 'lib/angular-sanitize/angular-sanitize.js',
        'angular-translate-interpolation-messageformat': 'lib/angular-translate/angular-translate-interpolation-messageformat/angular-translate-interpolation-messageformat.js',
        'angular-translate-loader-url': 'lib/angular-translate/angular-translate-loader-url/angular-translate-loader-url.js',
        'angular-translate-loader-static-files': 'lib/angular-translate/angular-translate-loader-static-files/angular-translate-loader-static-files.js',
        'dynamicLocale': 'lib/angular-dynamic-locale/tmhDynamicLocale.js',
        'fbsdk': 'https://connect.facebook.net/en_US/sdk.js',
        'googlesdk': 'https://apis.google.com/js/platform.js',
        'angular-cookies': 'lib/angular-cookies/angular-cookies.js',
        'angular-messages': 'lib/angular-messages/angular-messages.js',
        'framework.module': 'client/components/framework.module',
        'angular-drag-and-drop-lists': 'lib/angular-drag-and-drop-lists/angular-drag-and-drop-lists.js',
        'ios-drag-drop': 'lib/drag-drop-webkit-mobile/ios-drag-drop.js',
        'angular-dropdown-multiselect': 'lib/angular-dropdown-multiselect/angular-dropdown-multiselect.js',
        'card':'lib/angular-card/card.js',
        'angular-card':'lib/angular-card/angular-card.js',
        'angular-ui-router': 'lib/angular-ui-router/angular-ui-router.js',
        'angular-ui-mask': 'lib/angular-ui-mask/mask.js',
        'ui-select': 'lib/angular-ui-select/select.js',
        'credit-card-type': 'lib/credit-card-type/credit-card-type.js',
        'jsencrypt':'lib/jsencrypt/jsencrypt.js',
        'analytics': 'lib/analytics/booking-engine-analytics.js',
        'angular-media-queries':'lib/angular-media-queries/match-media.js',
        'ui-tinymce-theme': 'lib/tinymce/themes/silver/theme.min.js',
        'tinymce': 'lib/tinymce/tinymce-core.min.js',
        'angular-ui-tinymce': 'lib/angular-ui-tinymce/tinymce.min.js',
        'tinymce-plugins': 'client/components/angular-tinymce-plugin-loader.js',
        'datetime-difference': 'lib/datetime-difference/index.js',
        'image-cropper': 'lib/image-cropper/ui-cropper.js',
        'google-libphonenumber': 'lib/libphonenumber/libphonenumber.js',
        'angular-recaptcha': 'lib/angular-recaptcha/angular-recaptcha.js',
         'beforeunload-request': 'lib/beforeunload-request/beforeunload-request.min.js',
    },
    paths: {
        "booking-engine/client/": "client/",
        "tinymce-plugin/" : "lib/tinymce/",
    },
    packages: {
        'client' : {
            defaultExtension: 'js',
        },
    }        
});